@use '../../../../themes/default.scss' as theme;
@use "sass:map";

.app-sidebar {
  &.ant-layout-sider {
    background-color: theme.$dark_blue;
    width: 224px;
  }

  .ant-menu.ant-menu-dark, .ant-menu-dark .ant-menu-sub, .ant-menu.ant-menu-dark .ant-menu-sub{
    color: #999;
    background-color: theme.$dark_blue;
  }

  .ant-menu-dark {
    color: #999;
    background-color: theme.$dark_blue;

    &.ant-menu-inline {
      color: #999;
      background-color: theme.$dark_blue;
    }

    .ant-menu-sub {
      color: #999;
      background-color: theme.$dark_blue;
    }

    .ant-menu-submenu-selected:not(.ant-menu-submenu-open) {
      background-color: #494949;
    }
    
    .ant-menu-submenu-open {
      color: #999;
    }
    
    .ant-menu-submenu-selected {
      color: theme.$primary-color;
    }

    .ant-menu-submenu-title:hover, .ant-menu-submenu:hover {
      color: theme.$primary-color;
    }

    .ant-menu-item, .ant-menu-submenu-title {
      & > a {
        color: #999;
      }
      &:hover > a {
        color: theme.$primary-color;
      }
    }

    &.ant-menu-inline {
      .ant-menu-sub {
        background: #333;
      }

      .ant-menu-item-selected {
        background-color: theme.$dark_blue;
      }
    }

    &.ant-menu-inline-collapsed {
      .ant-menu-item-selected {
        background-color: theme.$dark_blue;
      }
    }

    &.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected{
      background-color: theme.$primary-color-dark;
    }

    .ant-menu-item-selected {
      color: theme.$primary-color;
      background-color: theme.$dark_blue;
      & > a {
        color: theme.$primary-color;
      }
      &:hover {
        background-color: theme.$dark_blue;
      }
    }
  }

  .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
    background-color: theme.$dark_blue;
  }

  .logo {
    margin: 16px;
    text-align: center;

    img {
      width: 122px !important;
      /*margin-right: 8px;*/
    }
  }

  .logo-min {
    margin: 16px;
    cursor: pointer;

    img {
      width: 50px !important;
    }
  }

  .bottom-options {
    width: 100%;
    position: absolute;
    bottom: 0;
    height: 48px;
    background-color: #494949;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    z-index: 1000;
  }
}