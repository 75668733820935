.layout__right-side-bar {
  position: absolute;
  overflow: visible;
  padding-bottom: 24px;
  height: 100vh;
  z-index: 520;
  right: 0px;
  top:0;

  .icon_close {
    color: #fff;
  }

  .icon_close:hover {
    color: #1890ff;
  }
}
