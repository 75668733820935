@mixin textOverflow() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.number-card {
  padding: 32px;
  //font-family: monospace;
  margin-bottom: 24px;
  cursor: pointer;

  .iconWarp {
    font-size: 54px;
    float: left;
  }

  .content {
    width: 100%;
    padding-left: 78px;

    .title {
      line-height: 16px;
      font-size: 16px;
      margin-bottom: 8px;
      height: 16px;
      @include textOverflow();
    }

    .number {
      line-height: 32px;
      font-size: 24px;
      height: 32px;
      @include textOverflow();
    }
  }
}


.number-card-lines {
  margin-bottom: 24px;
  cursor: pointer;
  box-shadow: 3px 3px 3px #eee;

  .flexBox {
    display: flex;
  }

  .iconWarp {
    color: #fff;
    font-size: 56px;
    padding: 30px 25px 20px 25px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  .content {
    flex: 1;
    padding: 16px;
    width: 100px;

    .title {
      line-height: 16px;
      font-size: 16px;
      margin-bottom: 8px;
      height: 16px;
      @include textOverflow();
    }

    .number {
      line-height: 32px;
      font-size: 24px;
      height: 32px;
      @include textOverflow();
    }
  }
}
