@use '../../../themes/default.scss' as theme;

.parametrica {
  /*float: left;*/
  /*width: "16.66%";*/
  width: "25%";
  text-align: center;
  list-style: none;
  cursor: pointer;
  /*height: 100px;*/
  color: #555;
  -webkit-transition: color .3s ease -in -out, background-color .3s ease -in -out;
  transition: color .3s ease -in -out, background-color .3s ease -in -out;
  position: relative;
  margin: 3px 0;
  border-radius: 4px;
  background-color: #fff;
  overflow: hidden;
  padding: 10px 0 0;

  .anticon {
    color: theme.$primary-color;
    font-size: 28px;
    padding: 10px
  }
}