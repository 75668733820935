.ant-notification-error{
  border: 1px solid #ffa39e;
  background-color: #fff1f0 !important;  
}

.ant-notification-error .ant-notification-notice-content 
.ant-notification-notice-with-icon
.ant-notification-notice-description {
  font-size: 12px;
}

.ant-notification-error .ant-notification-notice-content 
.ant-notification-notice-with-icon
.ant-notification-notice-message {
  font-size: 14px;
}

.ant-notification-success{
  border: 1px solid #b7eb8f;
  background-color: #f6ffed !important;
}

.ant-notification-success .ant-notification-notice-content 
.ant-notification-notice-with-icon
.ant-notification-notice-description {
  font-size: 12px;
}

.ant-notification-success .ant-notification-notice-content 
.ant-notification-notice-with-icon
.ant-notification-notice-message {
  font-size: 14px;
}

.ant-notification-warning {
  border: 1px solid #ffe58f;
  background-color: #fffbe6 !important;
}

.ant-notification-warning .ant-notification-notice-content 
.ant-notification-notice-with-icon
.ant-notification-notice-description {
  font-size: 12px;
}

.ant-notification-warning .ant-notification-notice-content 
.ant-notification-notice-with-icon
.ant-notification-notice-message {
  font-size: 14px;
}

.ant-notification-info {
  border: 1px solid #91d5ff;
  background-color: #e6f7ff !important;
}

.ant-notification-info .ant-notification-notice-content 
.ant-notification-notice-with-icon
.ant-notification-notice-description {
  font-size: 12px;
}

.ant-notification-info .ant-notification-notice-content 
.ant-notification-notice-with-icon
.ant-notification-notice-message {
  font-size: 14px;
}