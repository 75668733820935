.patente {
  display: block;
  background-color: #f5f4f5;
  box-shadow: 2px 2px 1px #888888;
  border: 2px solid;
  border-radius: 10px;
  
  .title {
    display: block;
    font-size: 7px;
    background-color: #0A408A;
    color: #ffffff;
    text-align: center;
    border: 1px solid #0A408A;
    border-radius: 6px 6px 0px 0px;
  }
  
  .title.remolque {
    background-color: #81252A;
    color: #ffffff;
    text-align: center;
    border: 1px solid #81252A;
    border-radius: 6px 6px 0px 0px;
  }
    
  .number {
    text-align: center;
    /*padding: 5px 5px 5px 5px;*/
    color: #000000;
    font-family: Trebuchet MS;
    font-size: 12px;
    font-weight: bold;
  }
}