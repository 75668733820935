@use '../../../../themes/default.scss' as theme;

.app-header {
  &.ant-layout-header {
    height: 48px;
    line-height: 52px;
    background: #fff;
    padding: 0;
  }

  .ant-layout-content {
    min-height: initial;
  }

  .header__badge-box {
    float: right;
    margin-right: 10px;
    display: flex;

    .header__badge {
      height: 48px;
      border-bottom: 2px solid rgb(62, 62, 62);
      margin-right: 6px;
      transition: border-color 0.3s ease-in;    

      &:hover {
        border-bottom: 2px solid theme.$primary-color;
        color: theme.$primary-color;
        cursor: pointer;
      }

      .size {
        font-size: 15px;
      }
    }
  }

  .header__position {
    line-height: 0px;
  }

  .trigger {
    font-size: 18px;
    line-height: 48px;
    padding: 0 16px;
    cursor: pointer;
    transition: color .3s;
  }

  .trigger:hover {
    color: theme.$primary-color;
  }
}

.app-footer{
  &.ant-layout-footer {
    text-align: center; 
    background-color: #fff; 
    border-top: 1px solid #eee; 
    height: 50px;
    line-height: 15px;
    padding: 15px 10px;
  }
}