@use '../../../themes/default.scss' as theme;

.validation {
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -210px 0 0 -420px;
  width: 760px;
  height: 520px;
  padding: 36px;
  //box-shadow: 0 0 100px rgba(0,0,0,.08);

  border: solid 1px theme.$primary-color;
  box-shadow: 12px 12px 12px 1px rgba(12, 0, 0, 0.2);
  border-radius: 10px;

  button {
    width: 40%;
  }
  swi

  p {
    color:#333333;
    text-align: center;
    margin-top: 16px;

    span {
      &:first-child {
        margin-right: 16px;
      }
    }
  }
  
  .logoWrapper {
    text-align: center;
    height: 120px;
    line-height: 40px;
    cursor: pointer;
    margin-bottom: 14px;
  
    .logoImg {
      width: 80px;
      /*margin-right: 8px;*/
    }

    span {
      vertical-align: text-bottom;
      font-size: 16px;
      text-transform: uppercase;
      display: block;
    }
  }
  
  .loginErrorMessage,
  .recoverErrorMessage {
    font-size: 14px;
    color: red;
  }

  .recoverSuccessMessage {
    font-size: 14px;
    color: green;
  }

}

// .ant-spin-container,
// .ant-spin-nested-loading {
//   height: 100vh!important;
// }
