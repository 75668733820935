
@use '../../themes/default.scss' as theme;

.steps-form-container {
  display: flex;
  flex-wrap: wrap;

  .steps-form-actions {
    margin-top: 24px;
  }

  .steps-form-box {
    //min-height: 350px;
  }

  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
    background-color: theme.$primary-color;
    border-color: theme.$primary-color-dark;
  }

  .ant-steps-item-finish .ant-steps-item-icon {
    border-color: theme.$primary-color-dark;
  }

  .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
    color: theme.$primary-color;
  }

  .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
    background-color: theme.$primary-color;
  }

  .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
    background-color: theme.$primary-color;
  }
}

.steps-vertical-layout {
  .steps {
    min-width: 300px;
  }

  .steps-form {
    flex: 1;
  }
}

.steps-horizontal-layout {
  overflow-y: auto;

  .steps {
    flex: 0 0 100%;
  }

  .steps-form {
    flex: 0 0 100%;
    
    .steps-form-fields {
      margin: 24px 0px;
    }
  }
}