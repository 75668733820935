.autocomplete-dropdown-container {
  padding: 10px;
  position: absolute;
  width: 100%;
  top: 35px !important;
  left: 0 !important;
}

.autocomplete-dropdown-container:empty {
  display: none;
}